/* Base styles */
* {
  font-family: 'Gotham', Arial, Helvetica, sans-serif;
  box-sizing: border-box !important;
}

body {
  margin: 0;
  width: 100vw;
  min-width: 320px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  color: black;
  background: #e8f1f3;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;

  button {
    outline: none !important;
    &:focus {
      outline: none !important;
    }
  }

  &.modal-open {
    overflow: hidden;
  }
  .ls-button {
    background: #2d9cdb;
  }
}

ol,
ul {
  list-style: none;
}

li {
  list-style: none;
}

svg {
  pointer-events: none;
  z-index: 1;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
  outline: none;

  &:hover,
  &:link,
  &:active,
  &:visited {
    outline: none !important;
  }

  &:hover {
    text-decoration: none;
  }
}

input,
textarea,
select {
  outline: none !important;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='text'] {
  -moz-appearance: textfield;

  &::-ms-clear {
    display: none;
  }
}

h1 {
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
}

input[type='password'] {
  &::-ms-reveal {
    display: none;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']:hover,
input[type='number']:focus {
  -moz-appearance: textfield;
}

button,
input[type='submit'] {
  display: block;
  background: transparent;
  border-radius: 0;
  transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none !important;
  }
}

// Modal animations
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes modalFadeDown {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes modalFadeUp {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-50%);
    opacity: 0;
  }
}

// End modal animations

// Blink animation for loading process
@keyframes blink {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

:root {
  --dark-blue-grey: #173042;
  --ice-blue: #edf0f2;
  --robin-s-egg-blue: #9ee7fb;
  --pine-green: #081a27;
  --seafoam-blue: #5be5be;
  --very-light-blue: #dfeef2;
  --black: #373636;
  --black-two: #000000;
  --ice-blue-two: #e8f1f3;
  --pale-grey: #f4f7f9;
  --light-blue-grey: #d1d6d9;
  --light-blue-grey-two: #dfeef2;
  --danger: #f16153;
  --success: #07bc0c;
  --info: #3498db;
  --warning: #f1c40f;
  --white: #fff;
  --white40: rgba(255, 255, 255, 0.4);
  --white80: rgba(255, 255, 255, 0.8);
  --grey: #a2acb3;
  --sky: #4eb3d4;
  --dark-grey: #838383;
  --dark-grey-two: #2d353b;
  --aquamarine: #4ed4b2;
  --shadow: #c2ccd6;
  --dark-gray10: rgba(23, 48, 66, 0.1);
  --light-gray75: rgba(237, 247, 251, 0.75);
  --ice-blue75: rgba(158, 231, 251, 0.75);
  --black-two33: rgba(0, 0, 0, 0.33);
  --black-two20: rgba(0, 0, 0, 0.2);
  --dark-blue70: rgba(8, 26, 39, 0.7);
  --dark-gray50: rgba(55, 54, 54, 0.5);
}
