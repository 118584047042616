@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Bold.otf');
  font-weight: bold;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'AtomicAge';
  src: url('../fonts/AtomicAge-Regular.ttf') format('truetype');
}
